export default class ActivityDateViewModel {
    public id: number = 0;
    public accountId: number = 0;
    public connections: number = 0;
    public messages: number = 0;
    public acceptanceRate: number = 0;
    public date: Date = new Date();
    public locked: boolean = false;
}

export class CreateActivityDateViewModel {
    public id?: number = undefined;
    public accountId: number = 0;
    public connections: number = 0;
    public messages: number = 0;
    public date: Date = new Date();
}

export class ActivitySummaryViewModel {
    public connections: number = 0;
    public messages: number = 0;
    public acceptanceRate: number = 0;
    public opportunities: number = 0;
    public followUps: number = 0;
    public withdrawns: number = 0;
    public pageInvites: number = 0;
    public paidInMails: number = 0;
    public freeInMails: number = 0;
}

export class ActivityListViewModel {
    public id: number = 0;
    public accountId: number = 0;
    public connections: number = 0;
    public messages: number = 0;
    public acceptanceRate: number = 0;
    public date: Date = new Date();
    public opportunities: number = 0;
    public followUps: number = 0;
    public followId: number = 0;
    public withdrawns: number = 0;
    public withdrawnId: number = 0;
    public pageInvites: number = 0;
    public pageInviteId: number = 0;
    public paidInMails: number = 0;
    public freeInMails: number = 0;
    public paidInMailsId: number = 0;
    public freeInMailsId: number = 0;
    public submittedById: string = '';
    public submittedBy: string = '';
    public locked: boolean = false;
}