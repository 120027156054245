export default class GetContactViewModel {
    public id = 0;
    public firstName: string = '';
    public lastName: string = '';
    public email: string = '';
    public phone: string = '';
    public account: string = '';
    public clientPrimary: boolean = false;
    public accountPrimary: boolean = false;

}