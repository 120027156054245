import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ActivityTable from './ActivityTable';
import MonthlyActivity from './MonthlyActivity';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ActivitySummaryViewModel } from '../../ViewModels/ActivityDateViewModel';
import authService from '../../api-authorization/AuthorizeService';
import Button from '@mui/material/Button';
import WithdrawnDialog from '../WithdrawnDialog';
import ReportModal from './ReportModal';
import Box from '@mui/material/Box';
import ProgressBar from './ProgressBar';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginBottom: theme.spacing(2)
        }
    })
);

interface IActivityProps {
    accountId: number;
    active: boolean;
    accountName: string;
    accountManager: string;
    clientName: string;
}

export default function Activity(props: IActivityProps) {
    const classes = useStyles();
    const [activity, setActivity] = React.useState(new ActivitySummaryViewModel());
    const [loaded, setLoaded] = React.useState(true);
    const [openWithdrawDialog, setWithdrawOpen] = React.useState(false);
    const [reportDialog, setReportDialog] = React.useState(false);
    const [progress, setProgress] = React.useState(0);
    const [progressPercentage, setProgressPercentage] = React.useState(0);

    const getData = React.useCallback(async () => {
        setLoaded(false);
        const token = await authService.getAccessToken();

        fetch(`Account/GetActivitySummary?accountId=${props.accountId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                setActivity(data);
                const prog = data.messages + data.paidInMails + data.freeInMails;
                if (prog) {
                    setProgress(prog);
                }
                else {
                    setProgress(0);
                }
                const progPercentage = Math.min(Math.round((prog / 500) * 100), 100);
                if (progPercentage) {
                    setProgressPercentage(progPercentage);
                }
                else {
                    setProgressPercentage(0);
                }
                setLoaded(true);
            })
            .catch(error => {
                console.log('Activity list error: ' + error);
                setLoaded(true);
            });

        fetch(`Account/GetWithdrawnToday?accountId=${props.accountId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                setLoaded(true);
            })
            .catch(error => {
                console.log('Get withdrawn error: ' + error);
                setLoaded(true);
            });

    }, [props.accountId]);
    React.useEffect(() => { getData(); }, [getData]);



    const handleDialogClose = (refresh: boolean) => {
        setWithdrawOpen(false);

        if (refresh) {
            getData();
        }
    }



    return (
        <div className={classes.root}>
            <Grid container spacing={2} justifyContent="space-between">
                <Grid item>
                    <Typography variant="h5" gutterBottom>Activity</Typography>
                </Grid>
                <Grid item>
                    <Button variant="outlined" color="secondary" onClick={() => setReportDialog(true)}>Generate Report</Button>
                </Grid>

            </Grid>
            <MonthlyActivity activity={activity} loaded={loaded} />
            <Box sx={{ width: '100%' }}>
                <ProgressBar progress={progress} progressPercentage={progressPercentage} />
            </Box>
            <ReportModal accountId={props.accountId} clientName={props.clientName} accountName={props.accountName} open={reportDialog} handleClose={() => setReportDialog(false)} />
            <ActivityTable accountId={props.accountId} refresh={getData} active={props.active} />
            <WithdrawnDialog accountId={props.accountId} open={openWithdrawDialog} close={handleDialogClose} date={new Date()} />
        </div>
    );
}