import CreateRenewalViewModel from "../Renewals/ViewModels/CreateRenewalViewModel";
import ActivityDateViewModel from "./ActivityDateViewModel";

export default class LinkedInAccountViewModel {
    public id: number = 0;
    public name: string = '';
    public userName: string = '';
    public password: string = '';
    public renewalDate: number = 0;
    public agreementEnd: Date | null = null;
    public startDate: Date = new Date();
    public actualStartDate: Date = new Date();
    public campaignId: number = 0;
    public campaignName: string = '';
    public campaignEmail: string = '';
    public campaignPhone: string = '';
    public campaignPartner: string = '';
    public packageId: number = 0;
    public packageName: string = '';
    public price: number = 0;
    public countryId: number = 0;
    public country: string = '';
    public agreementLength: string = '';
    public activityDates: ActivityDateViewModel[] = [];
    public opportunities: OpportunityViewModel[] = [];
    public withdrawnDates: number = 0;
    public followUpDates: number = 0;
    public pageInviteDates: number = 0;
    public executives: string[] = [];
    public status: string = '';
    public campaignTeamLeaderId: string = '';
    public campaignTeamLeader: string = '';
    public campaignTeamLeaderImageUrl: string = '';
    public campaignOpsManagerId: string = '';
    public campaignOpsManager: string = '';
    public campaignOpsManagerImageUrl: string = '';
    public mainExecutiveId: string = '';
    public mainExecutive: string = '';
    public mainExecutiveImageUrl: string = '';
    public googleSheetId: string = '';
    public vpnNumber: string = "";
    public opportunityWeightings: OpportunityWeightingViewModel[] = [];
    public weeklyTargetOpportunities: number = 0;
    public contactEmail: string = '';
    public contactPhone: string = '';
    public discount: string = '';
    public velocityManager: string = '';
    public velocityManagerId: string = '';
    public velocityManagerImageUrl: string = '';
    public archived: boolean = false;
    public autoSendCSS: boolean = false;
}

export class LinkedInAccountListViewModel {
    public id: number = 0;
    public name: string = '';
    public username: string = '';
    public password: string = '';
    public renewalDate: number = 0;
    public agreementEnd: Date | null = null;
    public startDate: Date = new Date();
    public actualStart: Date = new Date();
    public campaignId: number = 0;
    public campaignName: string = '';
    public packageName: string = '';
    public price: string = '';
    public country: string = '';
    public agreementLength: string = '';
    public activityLogged: boolean = false;
    public connections: number = 0;
    public messages: number = 0;
    public opportunities: number = 0;
    public acceptanceRate: number = 0;
    public status: string = '';
    public followUps: number = 0;
    public withdrawnDates: number = 0;
    public pageInviteDates: number = 0;
    public archived: boolean = false;
    public isManager: boolean = false;
    public isExecutive: boolean = false;
    public isVelocityManager: boolean = false;
    public mainExecutive: string = '';
    public userAccounts: [] = [];
    mainExecutiveId: any;
    public paidInMails: number = 0;
    public freeInMails: number = 0;
    public isTeamLeader: boolean = false;
    public noCallNoteForTwoWeeks: boolean = false;
}

export class CreateLinkedInAccountViewModel {
    public name: string = '';
    public username: string = '';
    public password: string = '';
    public contactEmail: string = '';
    public contactPhone: string = '';
    public campaignId: number = 0;
    public ref_CountryId: number = 0;
    public renewal: CreateRenewalViewModel = new CreateRenewalViewModel();
}

export class CreateOpportunityViewModel {
    public accountId: number = 0;
    public firstName: string = '';
    public lastName: string = '';
    public link: string = '';
    public companyName: string = '';
    public notes: string = '';
    public email: string = '';
    public phoneNumber: string = '';
    public country: string = '';
    public preferredContactMethodId: number = 0;
    public leadSource: string = "";
    public seniorityLevel: string = "";
    public constructor(id: number) {
        this.accountId = id;
    }
    public jobTitle: string = '';
    public location: string = '';
    public industry: string = '';
    public sizeOfCompany: string = '';
}

export class OpportunityViewModel {
    public accountId: number = 0;
    public name: string = '';
    public link: string = '';
    public companyName: string = '';
    public notes: string = '';
    public email: string = '';
    public phoneNumber: string = '';
    public id: number = 0;
    public timestamp: Date = new Date();
    public country: string = '';
    public preferredContactMethod: string = '';
    public industry: string = '';
    public sizeOfCompany: string = '';
    public leadSource: string = '';
    public seniorityLevel: string = '';
}

export class UserAccountViewModel {
    public userId: string = '';
    public accountId: number = 0;
    public startDate: Date = new Date();
    public endDate: Date | null = null;
}

export class UserAccountListViewModel {
    public id: number = 0;
    public accountId: number = 0;
    public name: string = '';
    public temp: boolean = false;
}

export class MessageViewModel {
    public id: number = 0;
    public title: string = '';
    public content: string = '';
    public accountId: number = 0;
}

export class CreateMessageViewModel {
    public title: string = '';
    public content: string = '';
    public accountId: number = 0;

    public constructor(id: number) {
        this.accountId = id;
        this.title = '';
        this.content = '';
    }
}

export class NoteViewModel {
    public id: number = 0;
    public author: string = '';
    public content: string = '';
    public type: string = '';
    public subType: string = '';
    public timestamp: Date = new Date();
    public client: boolean = false;
    public applicationContent: string = '';
}

export class GetUserListViewModel {
    name: string = '';
    email: string = '';
}

export class CreateNoteViewModel {
    public content: string = '';
    public commonReason: string = '';
    public noteTypeId: number = 0;
    public id: number = 0;
    public statusTo: string = '';
    public constructor(id: number) {
        this.id = id;
        this.noteTypeId = 0;
        this.content = '';
    }
    public emails: GetUserListViewModel[] = [];
}

export class CreateArchiveNoteViewModel {
    public content: string = '';
    public noteTypeId: number = 0;
    public id: number = 0;
    public constructor(id: number) {
        this.id = id;
    }
}

export class CreateAgreementEndChangedNoteViewModel {
    public content: string = '';
    public noteTypeId: number = 0;
    public id: number = 0;
    public agreementChangedTo: string | number | Date | null = null;
    public constructor(id: number, note: string, changeTo: string | number | Date | null) {
        this.id = id;
        this.content = note;
        this.noteTypeId = 15;
        this.agreementChangedTo = changeTo;
    }
}

export class CreatePackageChangedViewModel {
    public content: string = '';
    public noteTypeId: number = 0;
    public id: number = 0;
    public packageChangedTo: string | number | Date | null = null;
    public constructor(id: number, note: string, changeTo: string | number | Date | null) {
        this.id = id;
        this.content = note;
        this.noteTypeId = 14;
        this.packageChangedTo = changeTo;
    }
}

export class CreatePriceChangedViewModel {
    public content: string = '';
    public noteTypeId: number = 0;
    public id: number = 0;
    public priceChangedTo: string | number | Date | null = null;
    public constructor(id: number, note: string, changeTo: string | number | Date | null) {
        this.id = id;
        this.content = note;
        this.noteTypeId = 13;
        this.priceChangedTo = changeTo;
    }
}

export class CreateMainExecChangedViewModel {
    public content: string = '';
    public noteTypeId: number = 0;
    public id: number = 0;
    public mainExecChangedTo: string | number | Date | null = null;
    public constructor(id: number, note: string, changeTo: string | number | Date | null) {
        this.id = id;
        this.content = note;
        this.noteTypeId = 12;
        this.mainExecChangedTo = changeTo;
    }
}

export class CreatePackageViewModel {
    public accountId: number = 0;
    public packageName: string = '';
    public price: number = 0;
    public custom: boolean = false;
}

export class FollowUpViewModel {
    public id?: number = 0;
    public accountId: number = 0;
    public followUps: number = 0;
    public date: Date = new Date();
}
export class LockViewModel {
    public accountId: number = 0;
    public date: Date = new Date();
}
export class WithdrawnViewModel {
    public id?: number = 0;
    public accountId: number = 0;
    public withdrawn: number = 0;
    public date: Date = new Date();
}

export class PageInviteViewModel {
    public id?: number = 0;
    public accountId: number = 0;
    public pageInvites: number = 0;
    public date: Date = new Date();
}
export class InMailViewModel {
    public id?: number = 0;
    public accountId: number = 0;
    public freeInMails: number = 0;
    public paidInMails: number = 0;
    public date: Date = new Date();
}
export class OpportunityWeightingViewModel {
    public weightingId: number = 0;
    public weight: number = 0;
    public weightName: string = "";
}