import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Slider from "@mui/material/Slider";
import Typography from '@mui/material/Typography';
import { TextValidator } from 'react-material-ui-form-validator';
import { IClientCatchUpFormTabProps } from "./ClientCatchUpForm";



export default function ClientCatchUp3({ data, handleCheckChange, handleDropdownChange, handleTextChange, handleSliderChange }: IClientCatchUpFormTabProps) {
    const onSliderChange = (event: Event, value: number | number[], activeThumb: number) => {
        handleSliderChange("leadQualityVelocity", value as number);
    }
    return (
        <Paper sx={{ padding: "8px" }}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h4">Section 3</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Email Campaign</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        Great so now we have gone over the Linkedin side of your package I just wanted to touch upon the email campaign we have running for you.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">If Email is warming up:</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        "So as you know, your email campaign does take around 2 weeks to warm up. Your domain is currently in the warm up phase and looking healthy so I am confident it should be able to go live by... As soon as I have turned the email campaign on I will let you know to start expecting seeing those leads."
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="emailWarmUp"
                        value={data.emailWarmUp}
                        onChange={handleTextChange}
                        fullWidth
                        size="small"
                        multiline
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">If email campaign is live</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        "As you know the email campaign is now live so I did also include that in your weekly report, did you see the email stats?" "Great so very briefly I will just run you through those now."
                        What was the open rate? ( Average is 33%)

                        What was the response rate? (Average is 3-5%)

                        How many leads have we generated?
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="emailLive"
                        value={data.emailLive}
                        onChange={handleTextChange}
                        fullWidth
                        size="small"
                        multiline
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Update on Leads:*</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        "Great to now we have an outcome of those ones and some action points. Let's go through the new leads, what about? (mention by name)...."

                        Ask questions, if they have not been contacted create action points for both you and the client. Make it easy for them to follow and quick to do!
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="leadUpdateVelocity"
                        value={data.leadUpdateVelocity}
                        onChange={handleTextChange}
                        fullWidth
                        size="small"
                        multiline
                        validators={['required']}
                        errorMessages={['This field is required']}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6">Lead Quality*</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        "How are the quality of these leads? Are they of a higher or lower quality than LinkedIn or around the same?"
                    </Typography>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={10}>
                    <Box sx={{ paddingTop: '32px' }}>
                        <Slider
                            aria-label="Lead Quality Velocity"
                            value={data.leadQualityVelocity}
                            onChange={onSliderChange}
                            valueLabelDisplay="on"
                            step={1}
                            marks
                            min={1}
                            max={10}
                            sx={{ paddingTop: '32px' }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={1} />
            </Grid>
        </Paper>
    );
}