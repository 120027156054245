import * as React from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useLocation } from 'react-router';
import authService from '../api-authorization/AuthorizeService';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ReturnViewModel from '../ViewModels/ReturnViewModel';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import ClientCatchUpFormViewModel from './ClientCatchUpFormViewModel';
import ClientCatchUp1 from './ClientCatchUp1';
import ClientCatchUp2 from './ClientCatchUp2';
import ClientCatchUp3 from './ClientCatchUp3';
import ClientCatchUp4 from './ClientCatchUp4';


interface ClientCatchUpFormState {
    campaignId: number;
    accountId: number;
}

export interface IClientCatchUpFormTabProps {
    data: ClientCatchUpFormViewModel;
    handleCheckChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    handleDropdownChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleTextChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSliderChange: (name: string, value: number) => void
}

export default function ClientCatchUpForm() {
    const location = useLocation<ClientCatchUpFormState>();
    const { accountId } = location.state || {
        accountId: 0
    };

    const [clientCatchUpForm, setClientCatchUpForm] = React.useState<ClientCatchUpFormViewModel>(new ClientCatchUpFormViewModel());
    const [loaded, setLoaded] = React.useState(false);
    const [step, setStep] = React.useState(0);
    const [isUpdated, setIsUpdated] = React.useState(false);
    const [queueSave, setQueueSave] = React.useState(false);
    const [isVelocity, setIsVelocity] = React.useState(false);
    const [saving, setSaving] = React.useState(false);

    React.useEffect(() => {
        getData();
        const autosave = setInterval(() => {
            setQueueSave(true);
        }, 10000);
        return () => {
            clearTimeout(autosave);
            setQueueSave(false);
        }
    }, []);

    React.useEffect(() => {
        console.log(isUpdated + '|' + queueSave);
        if (isUpdated && queueSave) {
            autoSave();
        }
        setQueueSave(false);
    }, [queueSave]);

    React.useEffect(() => {
        setIsVelocity(clientCatchUpForm.package.includes('Velocity'));
    }, [clientCatchUpForm.package]);

    const getData = async () => {
        setLoaded(false);
        const token = await authService.getAccessToken();

        fetch(`Form/LoadCatchUp?accountId=${accountId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then((data: ReturnViewModel) => {
                setLoaded(true);
                if (data.success) {
                    setClientCatchUpForm(data.value);
                };
            })
            .catch(error => {
                console.log(error);
                setLoaded(true);
            });
    }

    const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setClientCatchUpForm({
            ...clientCatchUpForm,
            [event.target.name]: checked
        });
        setIsUpdated(true);
    }
    const handleDropdownChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setClientCatchUpForm({
            ...clientCatchUpForm,
            [event.target.name]: event.target.value
        });
        setIsUpdated(true);

    }
    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setClientCatchUpForm({
            ...clientCatchUpForm,
            [event.target.name]: event.target.value
        });
        setIsUpdated(true);

    }
    const handleSliderChange = (name: string, value: number) => {
            setClientCatchUpForm({
                ...clientCatchUpForm,
                [name]: value
            });
        setIsUpdated(true);
    }

    const back = () => {
        if (step == 3 && !isVelocity) {
            setStep(step - 2);
        }
        else {
            setStep(step - 1);
        }
    }
    const next = async () => {
        setLoaded(false);
        const token = await authService.getAccessToken();
        let data = clientCatchUpForm;
        data.complete = step === 3;
        if (step == 1 && !isVelocity) {
            setStep(step + 2);
        }
        else {
            setStep(step + 1);
        }

        fetch('Form/SaveCatchUp', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then((data: ReturnViewModel) => {
                setLoaded(true);
                if (data.success) {
                    setClientCatchUpForm(data.value);
                };
                
            })
            .catch(error => {
                console.log(error);
                setLoaded(true);
            });
    }

    const autoSave = async () => {
        setSaving(true);
        const token = await authService.getAccessToken();
        let data = clientCatchUpForm;

        fetch('Form/SaveCatchUp', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then((data: ReturnViewModel) => {
                setSaving(false);
                setIsUpdated(false);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const discoveryButton = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((props, ref) => (
        <RouterLink
            ref={ref}
            to={{
                pathname: '/Accounts/Account',
                state: { accountId }
            }}
            {...props} />
    ));

    const report = () => {
        fetch(`PDFGenerator/CatchUpReport?accountId=${accountId}`)
            .then(response => response.blob())
            .then(data => {
                console.log(data)
                if (data.type !== "application/problem+json") {
                    var dateTime = new Date();
                    var formattedTime = dateTime.toLocaleDateString();
                    var url = window.URL.createObjectURL(data);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = clientCatchUpForm.accountName + "_ClientDiscovery_" + formattedTime + ".pdf";
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                }
                else {
                    console.log('error', 'An internal server error occured');
                }
            });
    }
    return (
        <Container maxWidth="md" fixed sx={{ paddingTop: "12px" }}>
            {loaded &&
                <ValidatorForm onSubmit={next} autoComplete="off" instantValidate={true} >
                    <Grid container spacing={1} justifyContent="space-between">
                        <Grid item xs={12}>
                            <Typography variant="h5">Catch Up - {clientCatchUpForm.campaignName} - {clientCatchUpForm.accountName}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {step === 0 &&
                                <ClientCatchUp1 data={clientCatchUpForm} handleCheckChange={handleCheckChange} handleDropdownChange={handleDropdownChange} handleTextChange={handleTextChange} handleSliderChange={handleSliderChange} />
                            }
                            {step === 1 &&
                                <ClientCatchUp2 data={clientCatchUpForm} handleCheckChange={handleCheckChange} handleDropdownChange={handleDropdownChange} handleTextChange={handleTextChange} handleSliderChange={handleSliderChange} />
                            }
                            {(step === 2 && isVelocity) &&
                                <ClientCatchUp3 data={clientCatchUpForm} handleCheckChange={handleCheckChange} handleDropdownChange={handleDropdownChange} handleTextChange={handleTextChange} handleSliderChange={handleSliderChange} />
                            }
                            {step === 3 &&
                                <ClientCatchUp4 data={clientCatchUpForm} handleCheckChange={handleCheckChange} handleDropdownChange={handleDropdownChange} handleTextChange={handleTextChange} handleSliderChange={handleSliderChange} />
                            }
                            {step === 4 &&
                                <Typography align="center" variant="h6">Discovery Form Complete!</Typography>
                            }
                        </Grid>
                        <Grid item>
                            {(step > 0 && step < 4) && <Button variant="contained" color="primary" fullWidth onClick={back}>Back</Button>}
                            {step === 4 &&
                                <Button variant="outlined" color="primary" fullWidth onClick={report}>Download PDF</Button>
                            }
                        </Grid>
                        <Grid item>
                            {step === 4 ?
                                <Button variant="contained" color="primary" fullWidth component={discoveryButton}>Return to Account</Button>
                                :
                                <Button variant="contained" color="primary" disabled={saving} fullWidth type="submit">Save & Continue</Button>
                            }
                        </Grid>
                    </Grid>
                </ValidatorForm>
            }
        </Container >
    )

}