import Grid from "@mui/material/Grid";
import MenuItem from '@mui/material/MenuItem';
import Paper from "@mui/material/Paper";
import Typography from '@mui/material/Typography';
import { SelectValidator, TextValidator } from 'react-material-ui-form-validator';
import { IClientCatchUpFormTabProps } from "./ClientCatchUpForm";

export default function ClientCatchUp1({ data, handleCheckChange, handleDropdownChange, handleTextChange, handleSliderChange }: IClientCatchUpFormTabProps) {
    const subTitle = "Please make sure you have done the following before the call:\n- Dialed in on 8x8\n- Camera on\n- Get the clients weekly report & Impact page up\n- Log into the client account\n- Read over your call notes from the last call"
    return (
        <Paper sx={{ padding: "8px" }}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5">Section 1</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        {subTitle }
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Client Name</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="campaignName"
                        value={data.campaignName}
                        fullWidth
                        size="small"
                        multiline
                        readonly
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Is the Manager present on the call?*</Typography>
                </Grid>
                <Grid item xs={12}>
                    <SelectValidator
                        name="managerPresent"
                        value={data.managerPresent}
                        onChange={handleDropdownChange}
                        fullWidth
                        size="small"
                        validators={['required']}
                        errorMessages={['This field is required']}
                    >
                        <MenuItem key={0} value={"Yes"}>Yes</MenuItem>
                        <MenuItem key={1} value={"No"}>No</MenuItem>
                    </SelectValidator>
                </Grid>

            </Grid>
        </Paper>
    );
}