import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { SortReportViewModel } from '../ViewModels/ReportViewModel';

interface IReportProps {
    account: SortReportViewModel;
}

export default function ReportTableRow(props: IReportProps) {
    return (
        <TableRow tabIndex={-1} >
            <TableCell padding="none">{props.account.name}</TableCell>
            <TableCell padding="none">{props.account.campaignName}</TableCell>
            <TableCell padding="none">
                <Tooltip title={"Price: " + props.account.price}>
                    <div>{props.account.packageName}</div>
                </Tooltip>
            </TableCell>
            <TableCell padding="none">{props.account.country}</TableCell>
            <TableCell align="right" padding="none">£{props.account.monthlyPrice}</TableCell>
            <TableCell align="right" padding="none">{props.account.connections}</TableCell>
            <TableCell align="right" padding="none">{props.account.messages}</TableCell>
            <TableCell align="right" padding="none">{props.account.acceptanceRate}%</TableCell>
            <TableCell align="right" padding="none">{props.account.opportunitiesEmail}</TableCell>
            <TableCell align="right" padding="none">{props.account.opportunitiesPhone}</TableCell>
            <TableCell align="right" padding="none">{props.account.followUps}</TableCell>
            <TableCell align="right" padding="none">{props.account.pageInvites}</TableCell>
            <TableCell align="right" padding="none">{props.account.withdrawals}</TableCell>
            <TableCell align="right" padding="none">{props.account.freeInMails}</TableCell>
            <TableCell align="right" padding="none">{props.account.paidInMails}</TableCell>
        </TableRow>
    );
}