import * as React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Edit from '@mui/icons-material/Edit';
import Cancel from '@mui/icons-material/Cancel';
import Save from '@mui/icons-material/Save';
import green from '@mui/material/colors/green';
import DropdownViewModel from '../ViewModels/DropdownViewModel';
import CopyToClipboard from 'react-copy-to-clipboard';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Autocomplete } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        green: {
            color: green[500],
            '&:hover': {
                color: green[700],
            },
        },
        password: {
            paddingBottom: 0
        },
    })
);

interface IEditProps {
    decorator?: string;
    info: string ;
    date?: boolean;
    nullable?: boolean;
    label: string;
    selectId?: string;
    infoList: DropdownViewModel[];
    onSave: (newValue: string , fieldTitle?: string) => Promise<boolean>;
    hasClipboard?: boolean;
    fieldTitle?: string;
}

export default function InfoEdit(props: IEditProps) {
    const classes = useStyles();
    const [editing, setEditing] = React.useState(false);
    const [newInfo, setInfo] = React.useState(props.selectId ?? props.info);
    const [saving, setSaving] = React.useState(false);
    const [list, setList] = React.useState<string[]>([]);

    React.useEffect(() => setInfo(props.selectId ?? props.info), [editing, props.selectId, props.info]);
    React.useEffect(() => {
        let newList = props.infoList.map((options) => options.value);
        setList(newList);
    }, [props.infoList])

    const handleEdit = () => {
        var pastEdit = editing;

        setEditing(!editing);

        if (pastEdit) {
            setInfo(props.info);
        }
    }

    

    const save = () => {
        props.onSave(newInfo, props.fieldTitle).then(() => {
            setSaving(false);
            setEditing(false);
        });
    }


    const element = editing ?
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} style={{ paddingTop: 0 }}>
                <Typography variant="overline">{props.label}</Typography>
            </Grid>
            <Grid item xs={8} style={{ paddingTop: 0 }}>
                <Autocomplete
                    onChange={(event: any, newValue: string) => {
                        setInfo(newValue);
                    }}
                    value={newInfo}
                    options={list}
                    disableClearable
                    autoHighlight
                    autoComplete
                    autoSelect
                    fullWidth
                    renderInput={(params: any) =>
                        <TextField {...params} label={props.label}
                            variant="outlined"
                            value={newInfo}
                        />}
                />
                </Grid>
            
            <Grid item xs={4}>
                <Grid container justifyContent="flex-end" alignItems="center">
                    <Grid item>
                        <IconButton size="small" onClick={save} disabled={saving}><Save className={classes.green} /></IconButton>
                    </Grid>
                    <Grid item>
                        <IconButton size="small" onClick={handleEdit} disabled={saving}><Cancel color="error" /></IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
            :
            <Grid container spacing={2} alignItems="center">
                 <React.Fragment>
                    <Grid item xs={12} style={{ paddingTop: 0 }}>
                        <Typography variant="overline">{props.label}</Typography>
                    </Grid>
                    <Grid item xs={7} style={{ paddingTop: 0 }}>
                            <Typography>{props.decorator}{ props.info}</Typography>
                    </Grid>
                    <Grid item xs={5} style={{ paddingTop: 0 }}>
                        <Grid container justifyContent="flex-end">
                            
                            {props.hasClipboard !== undefined && props.hasClipboard &&
                                <Grid item>
                                    <CopyToClipboard text={props.info as string}
                                        onCopy={() => { }}>
                                        <IconButton size="small">
                                            <FileCopyIcon />
                                        </IconButton>
                                    </CopyToClipboard>
                                </Grid>
                            }
                            <Grid item>
                                <IconButton size="small" onClick={handleEdit} disabled={saving}><Edit color="secondary" /></IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </React.Fragment> 
                   
            </Grid>;

    return (
        <React.Fragment>
            {element}
        </React.Fragment>);
}

InfoEdit.defaultProps = {
    date: false
};