import Grid from "@mui/material/Grid";
import MenuItem from '@mui/material/MenuItem';
import Paper from "@mui/material/Paper";
import Typography from '@mui/material/Typography';
import { SelectValidator, TextValidator } from 'react-material-ui-form-validator';
import { IClientDiscoveryFormTabProps } from "./ClientDiscoveryForm";

export default function ClientDiscovery1({ data, handleCheckChange, handleDropdownChange, handleTextChange }: IClientDiscoveryFormTabProps) {
    return (
        <Paper sx={{ padding: "8px" }}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5">Section 1</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="h6">Have you looked at The Clients website?*</Typography>
                </Grid>
                <Grid item xs={4}>
                    <SelectValidator
                        name="websiteViewed"
                        value={data.websiteViewed}
                        onChange={handleDropdownChange}
                        fullWidth
                        validators={['required']}
                        errorMessages={['This field is required']}
                        size="small"
                        
                    >
                        <MenuItem key={0} value={"Yes"}>Yes</MenuItem>
                        <MenuItem key={1} value={"No"}>No</MenuItem>
                    </SelectValidator>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="h6">Client URL:</Typography>
                </Grid>
                <Grid item xs={4}>
                    <TextValidator
                        name="clientURL"
                        value={data.clientURL}
                        onChange={handleTextChange}
                        fullWidth
                        validators={['required']}
                        errorMessages={['This field is required']}
                        size="small"
                        
                        />
                </Grid>
            </Grid>
        </Paper>
    );
}