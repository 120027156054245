import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Typography from '@mui/material/Typography';
import { SelectValidator, TextValidator } from 'react-material-ui-form-validator';
import { IClientCatchUpFormTabProps } from "./ClientCatchUpForm";




export default function ClientCatchUp4({ data, handleCheckChange, handleDropdownChange, handleTextChange, handleSliderChange }: IClientCatchUpFormTabProps) {

    return (
        <Paper sx={{ padding: "8px" }}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h4">Section 4</Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6">Ending</Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6">Feedback for me: </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        "Are you happy with everything I am doing? Is there anything more I can be doing to make this campaign a success?"
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="communication"
                        value={data.communication}
                        onChange={handleTextChange}
                        fullWidth
                        size="small"
                        multiline
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6">Great so are there any final concerns or questions you have for me?*</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="concerns"
                        value={data.concerns}
                        onChange={handleTextChange}
                        fullWidth
                        size="small"
                        multiline
                        validators={['required']}
                        errorMessages={['This field is required']}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6">Round Up:*</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        "So on my end there are couple of bits to action for both of us. I am going to send you an email about this straight after the call but if you could...."    "and I'll....."

                        "Is there anything else you need from me?"
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="roundUp"
                        value={data.roundUp}
                        onChange={handleTextChange}
                        fullWidth
                        size="small"
                        multiline
                        validators={['required']}
                        errorMessages={['This field is required']}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6">When is your next call booked for?*</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        "Great so it's always beneficial to catch up, does this time in 2 weeks work for you?" // "So I know this catch up call is booked in as recurring, does that still work for you?"
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="nextCall"
                        value={data.nextCall}
                        onChange={handleTextChange}
                        fullWidth
                        size="small"
                        multiline
                        validators={['required']}
                        errorMessages={['This field is required']}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6">Suitable to send CSS: *</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        Don't do this every time but if you know its been a particular good call, you've just been introduced to this client or you think they might be holding something back on the call, send them this!

                        "I am also going to pop you down a super quick customer satisfaction survey to complete after this call. Just to give both me and my manager an indication or how I am doing. Is that ok?"
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <SelectValidator
                        name="css"
                        value={data.css}
                        onChange={handleDropdownChange}
                        fullWidth
                        size="small"
                        validators={['required']}
                        errorMessages={['This field is required']}
                    >
                        <MenuItem key={0} value={"Yes"}>Yes</MenuItem>
                        <MenuItem key={1} value={"No"}>No</MenuItem>
                    </SelectValidator>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6">Extra Notes</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="extraNotes"
                        value={data.extraNotes}
                        onChange={handleTextChange}
                        fullWidth
                        size="small"
                        multiline
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6">Follow Up Email Sent: *</Typography>
                </Grid>
                <Grid item xs={12}>
                    <SelectValidator
                        name="followUpEmail"
                        value={data.followUpEmail}
                        onChange={handleDropdownChange}
                        fullWidth
                        size="small"
                        validators={['required']}
                        errorMessages={['This field is required']}
                    >
                        <MenuItem key={0} value={"Yes"}>Yes</MenuItem>
                        <MenuItem key={1} value={"No"}>No</MenuItem>
                    </SelectValidator>
                </Grid>
            </Grid>
        </Paper>
    );
}