import * as React from 'react';
import Paper from '@mui/material/Paper';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import authService from '../../api-authorization/AuthorizeService';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
import { Redirect } from 'react-router';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CampaignViewModel from '../../ViewModels/CampaignViewModel';
import InfoEdit from '../../Utilities/InfoEdit';
import DropdownViewModel from '../../ViewModels/DropdownViewModel';
import RefIndustryViewModel from '../ViewModels/RefIndustryViewModel';
import AutocompleteInfoEdit from '../../Utilities/AutocompleteInfoEdit';
import RefIndustryExtendedViewModel from '../ViewModels/RefIndustryExtendedViewModel';
import { PermissionCheck } from '../../Contexts/PermissionCheck';
import ManageContacts from '../../Contacts/ManageContacts';
import ReturnViewModel from '../../ViewModels/ReturnViewModel';
import Typography from '@mui/material/Typography';
import GetContactsViewModel from '../../Contacts/ViewModels/GetContactsViewModel';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2)
        },
        showButton: {
            padding: 8,
        },
        bottomSpacing: {
            marginBottom: theme.spacing(2)
        },
        password: {
            paddingBottom: 0
        },
    })
);

interface IInfoProps {
    campaignId: number;
    clientName: string;
    setClientName: React.Dispatch<React.SetStateAction<(string)>>;
}

export default function CampaignInfo(props: IInfoProps) {
    const classes = useStyles();
    const [loaded, setLoaded] = React.useState(false);
    const [campaign, setCampaign] = React.useState(new CampaignViewModel());
    const [redirectToList, setRedirect] = React.useState(false);
    const [openWarning, setOpen] = React.useState(false);
    const [countries, setCountries] = React.useState<DropdownViewModel[]>([]);
    const [industries, setIndustries] = React.useState<RefIndustryExtendedViewModel[]>([]);
    const [dropIndustries, setDropIndustries] = React.useState<DropdownViewModel[]>([]);
    const [openContacts, setOpenContacts] = React.useState(false);
    const [primaryContact, setPrimaryContact] = React.useState<GetContactsViewModel>(new GetContactsViewModel());

    React.useEffect(() => {
        fetch('Campaign/GetClientIndustryList')
            .then(response => response.json())
            .then(data => {
                setIndustries(data);
            })
    }, [])
    React.useEffect(() => {
        setDropIndustries(industries.map((option) => (convertToDropdown(option))));
    }, [industries])

    React.useEffect(() => {
        getData();
    }, [props.campaignId]);

    const getData = async () => {
        setLoaded(false);
        const token = await authService.getAccessToken();

        fetch(`Campaign/GetCampaign?campaignId=${props.campaignId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                setCampaign(data);
                setLoaded(true);
            })
            .catch(error => {
                console.log('campaign error: ' + error);
                setLoaded(true);
            });
        fetch('Account/GetCountries', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                setCountries(data);
            });
        fetch(`Contact/GetClientPrimaryContact?clientId=${props.campaignId}`)
            .then(response => response.json())
            .then(data => {
                let ren: ReturnViewModel = data;
                setPrimaryContact(ren.value);
            });
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const convertToDropdown = (option: RefIndustryViewModel) => {
        let dropDown: DropdownViewModel = new DropdownViewModel();
        dropDown.id = option.id;
        dropDown.value = option.industry;
        return dropDown;
    };
    const updateContactName = async (value: string | number | Date | null) => {
        try {
            const token = await authService.getAccessToken();

            const response = await fetch(`Campaign/UpdateContactName?id=${props.campaignId}&contactName=${value}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                method: 'POST'
            });
            if (response.ok) {
                setCampaign({
                    ...campaign,
                    contactName: value as string
                });
            }
            return true;

        } catch (error) {
            console.log('Update error: ' + error);
            return true;
        }
    };
    const updateIndustry = async (value: string) => {
        console.log(value)
        let industryID = industries.find(s => s.industry === value)?.id;
        try {
            const token = await authService.getAccessToken();

            fetch(`Campaign/UpdateIndustry?id=${props.campaignId}&industryId=${industryID}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                method: 'POST'
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        getData();
                    }
                })

            return true;

        } catch (error) {
            console.log('Update error: ' + error);
            return true;
        }
    };
    const updateCountry = async (value: string | number | Date | null) => {
        try {
            const token = await authService.getAccessToken();

            const response = await fetch(`Campaign/UpdateCountry?id=${props.campaignId}&countryId=${value}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                method: 'POST'
            });
            const data = await response.json();
            if (response.ok) {
                setCampaign({
                    ...campaign,
                    countryId: data.id,
                    country: data.name
                });
            }
            return true;

        } catch (error) {
            console.log('Update error: ' + error);
            return true;
        }
    }
    const updatePartner = async (value: string | number | Date | null) => {
        try {
            const token = await authService.getAccessToken();

            const response = await fetch(`Campaign/UpdatePartner?id=${props.campaignId}&partner=${value}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                method: 'POST'
            });
            if (response.ok) {
                setCampaign({
                    ...campaign,
                    partner: value as string
                });
            }
            return true;

        } catch (error) {
            console.log('Update error: ' + error);
            return true;
        }
    };
    //const updateContactPhone = async (value: string | number | Date | null) => {
    //    console.log(value?.valueOf());
    //    try {
    //        const token = await authService.getAccessToken();

    //        const response = await fetch(`Campaign/UpdateContactPhone?id=${props.campaignId}&contactPhone=${value}`, {
    //            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
    //            method: 'POST'
    //        });
    //        if (response.ok) {
    //            setCampaign({
    //                ...campaign,
    //                contactPhone: value as string
    //            });
    //        }
    //        return true;

    //    } catch (error) {
    //        console.log('Update error: ' + error);
    //        return true;
    //    }
    //};
    //const updateContactEmail = async (value: string | number | Date | null) => {
    //    try {
    //        const token = await authService.getAccessToken();

    //        const response = await fetch(`Campaign/UpdateContactEmail?id=${props.campaignId}&contactEmail=${value}`, {
    //            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
    //            method: 'POST'
    //        });
    //        if (response.ok) {
    //            setCampaign({
    //                ...campaign,
    //                contactEmail: value as string
    //            });
    //        }
    //        return true;

    //    } catch (error) {
    //        console.log('Update error: ' + error);
    //        return true;
    //    }
    //};
    const updateStartDate = async (value: string | number | Date | null) => {
        try {
            const token = await authService.getAccessToken();

            const response = await fetch(`Campaign/UpdateStartDate?id=${props.campaignId}&startDate=${(value as Date).toJSON()}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                method: 'POST'
            });
            if (response.ok) {
                setCampaign({
                    ...campaign,
                    startDate: value as Date
                });
            }
            return true;

        } catch (error) {
            console.log('Update error: ' + error);
            return true;
        }
    };
    const archiveClient = async () => {
        const token = await authService.getAccessToken();

        fetch(`Campaign/ArchiveCampaign?id=${props.campaignId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => {
                if (response.ok) {
                    setRedirect(true)
                }
            })
            .catch(error => {
                console.log('Update error: ' + error);
            });
    };

    const updateClientName = async (value: string | number | Date | null) => {
        props.setClientName(value as string);
        try {
            const token = await authService.getAccessToken();

            const response = await fetch(`Campaign/UpdateClientName?id=${props.campaignId}&clientName=${value}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                method: 'POST'
            });
            if (response.ok) {
                setCampaign({
                    ...campaign,
                    campaignName: value as string
                });
            }
            return true;

        } catch (error) {
            console.log('Update error: ' + error);
            return true;
        }
    };
    const openManageContacts = () => {
        setOpenContacts(true);
    }
    const handleConatctsClose = () => {
        getData();
        setOpenContacts(false);
    }
    return (
        <Paper className={classes.paper}>
            {redirectToList && <Redirect to="/Clients" />}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {
                        loaded ? <InfoEdit info={props.clientName} label="Client Name" onSave={updateClientName} permission="Actions.UpdateCampaignInfo" /> : <Skeleton />
                    }
                </Grid>
                <Grid item xs={12}>
                    {
                        loaded ? <InfoEdit info={campaign.partner} label="Partner" onSave={updatePartner} permission="Actions.UpdateCampaignInfo" /> : <Skeleton />
                    }
                </Grid>
                <Grid item xs={12}>
                    {
                        loaded ? <InfoEdit info={campaign.contactName} label="Contact Name" onSave={updateContactName} permission="Actions.UpdateCampaignInfo" /> : <Skeleton />
                    }
                </Grid>
                <Grid item xs={12}>
                    {!loaded ?
                        <Skeleton />
                        :
                        <React.Fragment>
                            <Typography variant="overline">Contact Phone</Typography>
                            <Typography>{primaryContact.phone ? primaryContact.phone : "-"}</Typography>
                        </React.Fragment>
                    }
                </Grid>
                <Grid item xs={12}>
                    {!loaded ?
                        <Skeleton />
                        :
                        <React.Fragment>
                            <Typography variant="overline">Contact Email</Typography>
                            <Typography>{primaryContact.email ? primaryContact.email : "-"}</Typography>
                        </React.Fragment>
                    }
                </Grid>
                <Grid item xs={12}>
                    {
                        loaded ? <InfoEdit selectId={campaign.countryId} info={campaign.country} label="Country" infoList={countries} onSave={updateCountry} permission="Actions.UpdateCampaignInfo" /> : <Skeleton />
                    }
                </Grid>

                <Grid item xs={12}>
                    {


                        loaded ? <AutocompleteInfoEdit info={campaign.industryExtended} label="Industry" infoList={dropIndustries} onSave={updateIndustry} /> : <Skeleton />

                    }
                </Grid>

                <Grid item xs={12}>
                    {
                            loaded ? <InfoEdit info={campaign.startDate} date label="Start Date" onSave={updateStartDate} permission="Actions.UpdateCampaignInfo" /> : <Skeleton />
                    }
                </Grid>
                <PermissionCheck permission="Actions.UpdateCampaignInfo">
                <Grid item xs={12}>
                    <Button fullWidth variant="outlined" color="secondary" style={{ textAlign: 'center' }} onClick={openManageContacts}>Manage Contacts</Button>
                    </Grid>
                </PermissionCheck>
                <PermissionCheck permission="Actions.ArchiveClient">
                    <Grid item xs={12}>
                        <Button fullWidth variant="outlined" color="secondary" onClick={handleClickOpen} disabled={!loaded}>Archive campaign</Button>
                    </Grid>
                    </PermissionCheck>

            </Grid>
            <Dialog
                open={openWarning}
                onClose={handleClose}
                aria-labelledby="warning-title"
                aria-describedby="warning-description"
            >
                <DialogTitle id="warning-title">{"Are you sure?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="warning-description">
                        This will permanently archive the client.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={archiveClient} color="primary" autoFocus>
                        Archive
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openContacts}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle>Manage Contacts</DialogTitle>
                <DialogContent>
                    <ManageContacts close={handleConatctsClose} clientId={props.campaignId} permission="Actions.UpdateCampaignInfo" />
                </DialogContent>
            </Dialog>
        </Paper>
    );
}