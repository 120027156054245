import * as React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LinkedInAccountViewModel, { CreateAgreementEndChangedNoteViewModel } from '../../ViewModels/LinkedInAccountViewModel';
import authService from '../../api-authorization/AuthorizeService';
import Skeleton from '@mui/material/Skeleton';
import InfoEdit from '../../Utilities/InfoEdit';
import DropdownViewModel from '../../ViewModels/DropdownViewModel';
import Button from '@mui/material/Button';
import { Redirect } from 'react-router';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import ArchiveDialog from '../ArchiveDialog';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import DialogActions from '@mui/material/DialogActions';
import ManageRenewals from '../../Renewals/ManageRenewals';
import CreateInfoEditNote from './CreateInfoEditNote';
import { PermissionCheck } from '../../Contexts/PermissionCheck';
import ManageContacts from '../../Contacts/ManageContacts';
import GetContactsViewModel from '../../Contacts/ViewModels/GetContactsViewModel';
import ReturnViewModel from '../../ViewModels/ReturnViewModel';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            marginBottom: theme.spacing(2)
        },
        showButton: {
            padding: 8
        },
        bottomSpacing: {
            marginBottom: theme.spacing(2)
        },
        password: {
            paddingBottom: 0
        }
    })
);

interface IInfoProps {
    accountId: number;
    refreshNotes: () => void;
    refreshAccount: () => void;
}

export default function AccountInfo(props: IInfoProps) {
    const classes = useStyles();
    const [loaded, setLoaded] = React.useState(false);
    const [account, setAccount] = React.useState(new LinkedInAccountViewModel());
    const [countries, setCountries] = React.useState<DropdownViewModel[]>([]);
    const [redirectToList, setRedirect] = React.useState(false);
    const [openWarning, setOpen] = React.useState(false);
    const [tempVal, setTempVal] = React.useState<string | number | Date | null>(null);
    const [openCreateNote, setOpenCreateNote] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);
    const [lpw, setLpw] = React.useState(0);
    const [openLpwReason, setLpwReason] = React.useState(false);
    const [reason, setReason] = React.useState("");
    const [openRenewals, setOpenRenewals] = React.useState(false);
    const [openContacts, setOpenContacts] = React.useState(false);
    const [primaryContact, setPrimaryContact] = React.useState<GetContactsViewModel>(new GetContactsViewModel());

    React.useEffect(() => {
        getData();
    }, [props.accountId]);

    const getData = async () => {
        setLoaded(false);
        const token = await authService.getAccessToken();
        fetch(`Account/GetAccount?id=${props.accountId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                setAccount(data);
                fetch(`Contact/GetAccountPrimaryContact?accountId=${props.accountId}`)
                    .then(response => response.json())
                    .then(data => {
                        let ren: ReturnViewModel = data;
                        setPrimaryContact(ren.value);
                    });
                setLoaded(true);
            })
            .catch(error => {
                console.log('Account error: ' + error);
                setLoaded(true);
            });



        fetch('Account/GetCountries', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                setCountries(data);
            });
    }

    const unarchive = async () => {
        setLoaded(false);
        const token = await authService.getAccessToken();
        fetch(`Account/Unarchive?id=${props.accountId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                setAccount(data);
                setLoaded(true);
            })
            .catch(error => {
                console.log('Account error: ' + error);
                setLoaded(true);
            });
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setLpwReason(false);
    };
    const updateWeeklyTargetWithReason = async () => {
        setSubmitting(true);
        try {
            const token = await authService.getAccessToken();

            const response = await fetch(`Account/UpdateWeeklyTarget?id=${props.accountId}&target=${lpw}&reason=${reason}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                method: 'POST'
            });
            if (response.ok) {
                setAccount({
                    ...account,
                    weeklyTargetOpportunities: lpw
                });
                setLpwReason(false);
                setSubmitting(false);
            } else {
                setSubmitting(false);
            }
            return true;

        } catch (error) {
            console.log('Update error: ' + error);
            setSubmitting(false);
            return true;
        }
    };
    const updateWeeklyTarget = async (value: string | number | Date | null) => {
        setLpw(value as number);
        setReason("");
        setLpwReason(true);
        return true;
    }
    const handleReasonChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setReason(event.target.value);
    }
    const redirect = (r: boolean) => {
        setRedirect(r);
    }
    const openNote = async (value: string | number | Date | null, fieldTitle?: string) => {
        setTempVal(value);
        setOpenCreateNote(true);
        return true;
    }


    const openManageRenewals = () => {
        setOpenRenewals(true);
    }
    const handleRenewalsClose = () => {
        props.refreshNotes();
        getData();
        setOpenRenewals(false);
    }
    const openManageContacts = () => {
        setOpenContacts(true);
    }
    const handleConatctsClose = () => {
        props.refreshNotes();
        getData();
        setOpenContacts(false);
    }
    const updateWeighting = async (value: string | number | Date | null, fieldTitle?: string) => {
        const token = await authService.getAccessToken();
        fetch(`Account/CreateCustomeWeighting?accountId=${props.accountId}&contactMethodId=${fieldTitle}&weight=${value}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
            })
            .catch(error => {
            });
        getData();
        return true;
    }

    const updateStartDate = async (value: string | number | Date | null) => {
        try {
            const token = await authService.getAccessToken();

            const response = await fetch(`Account/UpdateStartDate?id=${props.accountId}&date=${(value as Date).toJSON()}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                method: 'POST'
            });

            if (response.ok) {
                setAccount({
                    ...account,
                    actualStartDate: value as Date
                });
            }
            return true;
        } catch (error) {
            console.log('Update error: ' + error);
            return true;
        }
    }

    const updateCountry = async (value: string | number | Date | null) => {
        try {
            const token = await authService.getAccessToken();

            const response = await fetch(`Account/UpdateCountry?id=${props.accountId}&countryId=${value}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                method: 'POST'
            });
            const data = await response.json();
            if (response.ok) {
                setAccount({
                    ...account,
                    countryId: data.id,
                    country: data.name
                });
            }
            return true;

        } catch (error) {
            console.log('Update error: ' + error);
            return true;
        }
    }
    
    const updateName = async (value: string | number | Date | null) => {
        try {
            const token = await authService.getAccessToken();

            const response = await fetch(`Account/UpdateName?id=${props.accountId}&name=${value}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            const data = await response.text();
            if (response) {
                setAccount({
                    ...account,
                    name: data
                });
            }
            return true;

        } catch (error) {
            console.log('Update error: ' + error);
            return true;
        }
    }
    const submitNote = async (content: string) => {
        const token = await authService.getAccessToken();

        fetch(`Campaign/CreateAgreementEndChangeNote`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(new CreateAgreementEndChangedNoteViewModel(account.id, content, tempVal))
        })
            .then(response => response.text())
            .then(data => {
                updateAgreement(tempVal);
                setOpenCreateNote(false);
            })
            .then(() => {
                props.refreshNotes();
            })
            .catch(error => {
                console.log(error);
                setOpenCreateNote(false);
            });

    }

    const updateAgreement = async (value: string | number | Date | null) => {
        try {
            const token = await authService.getAccessToken();
            const date = value === null ? '' : `${(value as Date).toJSON()}`;
            const response = await fetch(`Account/UpdateAgreementEnd?id=${props.accountId}&date=` + date, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                method: 'POST'
            });

            if (response.ok) {
                setAccount({
                    ...account,
                    agreementEnd: value as Date | null
                });
            }
            return true;

        } catch (error) {
            console.log('Update error: ' + error);
            return true;
        }
    }

    const viewButton = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((props, ref) => (
        <RouterLink
            ref={ref}
            to={{
                pathname: '/Clients/Client',
                state: { campaignId: account.campaignId }
            }}
            {...props} />
    ));
    const opportunityWeightings = account.opportunityWeightings.map(m =>
        <Grid item xs={12} key={'oppWeight' + m.weightingId}>
            {
                loaded ? <InfoEdit info={m.weight} label={m.weightName + " Weighting"} onSave={updateWeighting} fieldTitle={m.weightingId.toString()} permission="" /> : <Skeleton />

            }
        </Grid>);
    return (
        <React.Fragment>
            <div className={classes.paper}>
                {redirectToList && <Redirect to="/Accounts" />}
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ paddingTop: 30 }}>
                        {
                            loaded ? <InfoEdit info={account.name} label="" onSave={updateName} permission="Actions.UpdateAccountInfo" /> : <Skeleton />
                        }
                    </Grid>
                    {
                        account.campaignPartner != null &&
                        <Grid item xs={12}>
                            {!loaded ?
                                <Skeleton />
                                :
                                <React.Fragment>
                                    <Typography>{loaded ? account.campaignPartner : <Skeleton />}</Typography>
                                    <Typography variant="caption">Partner</Typography>
                                </React.Fragment>
                            }
                        </Grid>
                    }
                    <Grid item xs={12}>
                        {!loaded ?
                            <Skeleton />
                            :
                            <React.Fragment>
                                <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                                    <Grid item>
                                        <Typography variant="overline">Client</Typography>
                                        <Typography>{loaded ? account.campaignName : <Skeleton />}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" color="secondary" size="small" component={viewButton} >View</Button>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        {!loaded ?
                            <Skeleton />
                            :
                            <React.Fragment>
                                <Typography variant="overline">Phone</Typography>
                                <Typography>{loaded ? account.campaignPhone : <Skeleton />}</Typography>
                            </React.Fragment>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        {!loaded ?
                            <Skeleton />
                            :
                            <React.Fragment>
                                <Typography variant="overline">Email</Typography>
                                <Typography>{loaded ? account.campaignEmail : <Skeleton />}</Typography>
                            </React.Fragment>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        {!loaded ?
                            <Skeleton />
                            :
                            <React.Fragment>
                                <Typography variant="overline">Contact Phone</Typography>
                                <Typography>{primaryContact.phone ? primaryContact.phone :"-"}</Typography>
                            </React.Fragment>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        {!loaded ?
                            <Skeleton />
                            :
                            <React.Fragment>
                                <Typography variant="overline">Contact Email</Typography>
                                <Typography>{primaryContact.email ? primaryContact.email : "-"}</Typography>
                            </React.Fragment>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        {
                            loaded ? <InfoEdit selectId={account.countryId} info={account.country} label="Country" infoList={countries} onSave={updateCountry} permission="Actions.UpdateAccountInfo" /> : <Skeleton />
                        }
                    </Grid>
                    <Grid item xs={12}>
                        {
                            loaded ? <InfoEdit info={account.actualStartDate} date label="Start Date" onSave={updateStartDate} permission="Actions.UpdateAccountInfo" /> : <Skeleton />
                        }
                    </Grid>
                    <Grid item xs={12}>
                        {
                            loaded ? <InfoEdit info={account.agreementEnd} date nullable label="Agreement End" onSave={openNote} fieldTitle="agreementEnd" permission="Actions.UpdateAccountInfo" /> : <Skeleton />
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <React.Fragment>
                            <Typography variant="overline">Package</Typography>
                            <Typography>{loaded ? account.packageName : <Skeleton />}</Typography>
                        </React.Fragment>
                    </Grid>
                    <Grid item xs={12}>
                        <React.Fragment>
                            <Typography variant="overline">Renewal Price</Typography>
                            <Typography>£{loaded ? account.price : <Skeleton />}</Typography>
                        </React.Fragment>
                    </Grid>
                    <Grid item xs={12}>
                        <React.Fragment>
                            <Typography variant="overline">Discount</Typography>
                            <Typography>{loaded ? account.discount : <Skeleton />}%</Typography>
                        </React.Fragment>
                    </Grid>
                    <Grid item xs={12}>
                        <React.Fragment>
                            <Typography variant="overline">Renewal Date</Typography>
                            {loaded ? <Typography>{account.renewalDate}</Typography> : <Skeleton />}
                        </React.Fragment>
                    </Grid>
                    {opportunityWeightings}
                    <Grid item xs={12}>
                        {loaded ?
                            <InfoEdit info={account.weeklyTargetOpportunities} label="LPW" onSave={updateWeeklyTarget} permission="" /> : <Skeleton />
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Button fullWidth variant="outlined" color="secondary" style={{ textAlign: 'center' }} onClick={openManageRenewals}>Manage Renewals</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button fullWidth variant="outlined" color="secondary" style={{ textAlign: 'center' }} onClick={openManageContacts}>Manage Contacts</Button>
                    </Grid>
                    <PermissionCheck permission="">
                        {account.archived ?
                            <Grid item xs={12}>
                                <Button fullWidth variant="outlined" color="secondary" onClick={unarchive} disabled={!loaded || account.status !== "Cancelled"}>Unarchive Account</Button>
                            </Grid>
                            :
                            <Grid item xs={12}>
                                <Button fullWidth variant="outlined" color="secondary" onClick={handleClickOpen} disabled={!loaded || account.status !== "Cancelled"}>Archive Account</Button>
                            </Grid>
                        }
                    </PermissionCheck>
                </Grid>
                <ArchiveDialog open={openWarning} onClose={handleClose} accountId={account.id} redirect={redirect} />
            </div>
            <Divider className={classes.paper} />
            <CreateInfoEditNote open={openCreateNote} onSubmit={submitNote} onClose={() => { setOpenCreateNote(false) }} />
            <Dialog
                open={openLpwReason}
                aria-labelledby="warning-title"
                aria-describedby="warning-description"
            >
                <DialogTitle id="warning-title">{"LPW change reason"}</DialogTitle>
                <ValidatorForm onSubmit={updateWeeklyTargetWithReason}>
                    <DialogContent>
                        <TextValidator
                            label="Reason"
                            name="reason"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            value={reason}
                            validators={['required']}
                            errorMessages={['This field is required']}
                            onChange={handleReasonChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} disabled={submitting} color="secondary">
                            Cancel
                        </Button>
                        <Button type="submit" disabled={submitting} color="primary" autoFocus>
                            Save
                        </Button>
                    </DialogActions>
                </ValidatorForm>
            </Dialog>
            <Dialog
                open={openRenewals}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle>Manage Renewals</DialogTitle>
                <DialogContent>
                    <ManageRenewals close={handleRenewalsClose} accountId={props.accountId} refreshAccount={props.refreshAccount} />
                </DialogContent>
            </Dialog>
            <Dialog
                open={openContacts}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle>Manage Contacts</DialogTitle>
                <DialogContent>
                    <ManageContacts close={handleConatctsClose} accountId={props.accountId} clientId={account.campaignId} permission="Actions.UpdateAccountInfo" />
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}