import * as React from 'react';
import authService from '../api-authorization/AuthorizeService';
import {PermissionsContext} from '../Contexts/PermissionsContext';

export default function PowerBI() {

    const [Access, SetAccess] = React.useState(false);
    const { checkHasPermission } = React.useContext(PermissionsContext);

    React.useEffect(() => {
        authService.getUser().then(user => {
            if (checkHasPermission("")) {

                SetAccess(true);
            }
        });
    }, []);



    return (
        <React.Fragment>
            {
                Access &&
                <div style={{ display: 'flex', width: '100%', height: '100%', flexDirection: 'column', overflow: 'hidden' }}>
                        <iframe style={{ flexGrow: 1, border: 'none', margin: 0, padding: 0, height: 'calc(100vh - 100px)' }} src="https://app.powerbi.com/reportEmbed?reportId=dc615e8d-0893-4439-b14a-95c7be85f195&autoAuth=true&ctid=7c73f318-8050-40b6-b6d2-60f617aa1490" ></iframe>
                </div>
            }
        </React.Fragment>
    );
}