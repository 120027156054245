import Grid from "@mui/material/Grid";
import { ChangeEvent, Fragment } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import CreateContactViewModel from "./ViewModels/CreateContactViewModel";


interface IProps {
    contact: CreateContactViewModel;
    setContact: (contact: CreateContactViewModel) => void;
}

export default function ContactDataEntry(props: IProps) {

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        props.setContact({
            ...props.contact,
            [event.target.name]: event.target.value
        });
    }

    ValidatorForm.addValidationRule('emailRegex', (value) => {
        if (value === "") {
            return true;
        }
        return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,6}$/.test(value);
    });
    return (
        <Fragment>
            <Grid container spacing={1}>
                <Grid item xs={3} >
                    <TextValidator
                        label="First Name"
                        name="firstName"
                        variant="outlined"
                        fullWidth
                        value={props.contact.firstName}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={3} >
                    <TextValidator
                        label="Last Name"
                        name="lastName"
                        variant="outlined"
                        fullWidth
                        value={props.contact.lastName}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={3} >
                    <TextValidator
                        label="Phone Number"
                        name="phone"
                        variant="outlined"
                        fullWidth
                        value={props.contact.phone}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={3} >
                    <TextValidator
                        label="Email"
                        name="email"
                        variant="outlined"
                        fullWidth
                        value={props.contact.email}
                        validators={[ 'emailRegex']}
                        errorMessages={['Must be a valid email']}
                        onChange={handleChange}
                    />
                </Grid>
            </Grid>
        </Fragment>
    );
}