import { FormControlLabel, MenuItem, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import authService from "../api-authorization/AuthorizeService";
import EODReportTeamDataViewModel from "./ViewModels/EODReportTeamDataViewModel";
import EODReportViewModel from "./ViewModels/EODReportViewModel";

export default function EODPerformanceReport() {
    const [report, setReport] = useState<EODReportViewModel | null>(null);
    const [isFiltering, setIsFiltering] = useState<boolean>(true);
    const [teams, setTeams] = useState<string[]>([]);
    const [filterTeam, setFilterTeam] = useState<string>("");
    const [teamsData, setTeamsData] = useState<EODReportTeamDataViewModel[]>([])


    // UseEffects
    useEffect(() => {
        initialise();
    }, []);

    // Methods
    const initialise = async () => {
        const token = await authService.getAccessToken();
        fetch(`DataAnalysis/EODPerformanceReport`)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setReport(data.value);
                    fetch('DataAnalysis/GetTeamsData', {
                        method: 'POST',
                        headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': "Bearer " + token, 'Content-Type': 'application/json; charset=utf-8' },
                        body: JSON.stringify(data.value)
                    })
                        .then(response => response.json())
                        .then(data => {
                            if (data.success) {
                                setTeamsData(data.value);
                            }
                        });
                }
            });

        fetch(`DataAnalysis/GetTeams`)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setTeams(data.value);
                }
            });
    }

    const handleSwitch = (event: any, checked: boolean) => {
        setIsFiltering(checked);
    }

    const filterTeamChange = (event: any) => {
        setFilterTeam(event.target.value);
    }

    //const teamDataDisplay = teamsData.map(team => {
    //    <React.Fragment>
    //        <Grid item xs={12}>
    //            <Typography variant="h6">{team.teamName}</Typography>
    //        </Grid>
    //        <Grid item xs={12}>
    //            <Typography variant="h6">Total Accounts: {team.totalAccounts}</Typography>
    //        </Grid>
    //        <Grid item xs={12}>
    //            <Typography variant="h6">Locked: {team.totalLocked}</Typography>
    //        </Grid>
    //        <Grid item xs={12}>
    //            <Typography variant="h6">Underperformed: {team.totalUnderperformed}</Typography>
    //        </Grid>
    //    </React.Fragment>

    //})

    return (
        <Grid container spacing={1}>
            <Grid item xs={4}>
                <Typography variant="h4">End of Day Performance Report</Typography>
            </Grid>
            <Grid item xs={4}>
                <FormControlLabel
                    label="Underperformance"
                    control={<Switch
                        checked={isFiltering}
                        onChange={handleSwitch}
                    />}
                />

            </Grid>
            <Grid item xs={4}>
                <TextField
                    select
                    fullWidth
                    value={filterTeam}
                    onChange={filterTeamChange}
                    size="small"
                >
                    <MenuItem key={-1} value="">All Teams</MenuItem>
                    {teams.map(item => {
                        return <MenuItem key={item} value={item}>
                            {item}
                        </MenuItem>;
                    })}
                </TextField>
            </Grid>
            <Grid item xs={10} >
                {report == null ?
                    <CircularProgress />
                    :
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Team</TableCell>
                                    <TableCell>Client</TableCell>
                                    <TableCell>Account</TableCell>
                                    <TableCell>Executive</TableCell>
                                    <TableCell>Connections</TableCell>
                                    <TableCell>Messages</TableCell>
                                    <TableCell>Acceptance %</TableCell>
                                    <TableCell>Follow Ups</TableCell>
                                    <TableCell>Email Ops</TableCell>
                                    <TableCell>Phone Ops</TableCell>
                                    <TableCell>InMails</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{ overflowY: "scroll" }}>
                                {report.accounts.filter(f => (!isFiltering || f.underperformance) && (filterTeam === "" || f.teamName === filterTeam)).map((row) => (
                                    <TableRow key={row.accountId}>
                                        <TableCell>{row.teamName}</TableCell>
                                        <TableCell>{row.clientName}</TableCell>
                                        <TableCell>{row.accountName}</TableCell>
                                        <TableCell>{row.executiveName}</TableCell>
                                        <TableCell sx={row.connectionStatus === "Success" ? { backgroundColor: "#34a853", color: "white" } : row.connectionStatus === "Late" ? { backgroundColor: "#fbbc04", color: "white" } : { backgroundColor: "#ea4335", color: "white" }}>{row.connections}</TableCell>
                                        <TableCell>{row.messages}</TableCell>
                                        <TableCell>{row.acceptanceRate}</TableCell>
                                        <TableCell sx={row.followUpsStatus === "Success" ? { backgroundColor: "#34a853", color: "white" } : row.followUpsStatus === "Late" ? { backgroundColor: "#fbbc04", color: "white" } : { backgroundColor: "#ea4335", color: "white" }}>{row.followUps}</TableCell>
                                        <TableCell>{row.emailOps}</TableCell>
                                        <TableCell>{row.phoneOps}</TableCell>
                                        <TableCell sx={row.inMailStatus === "Success" ? { backgroundColor: "#34a853", color: "white" } : row.inMailStatus === "Late" ? { backgroundColor: "#fbbc04", color: "white" } : { backgroundColor: "#ea4335", color: "white" }}>{row.inMails}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
            </Grid>
            <Grid item xs={2}>
                <Grid container spacing={1}>
                    {!(filterTeam) &&
                        <Grid item xs={12}>
                            <Paper style={{ padding: 8 }}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">All Teams</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body1">Total Accounts: {report?.accounts.length}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body1">Locked: {report?.totalLocked}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body1">Underperformed: {report?.totalUnderperformed}</Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    }
                    {teamsData.filter(f => (filterTeam === "" || f.teamName === filterTeam)).map(team => {
                        return <Grid item xs={12}>
                            <Paper style={{ padding: 8 }}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">{team.teamName}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body1">Total Accounts: {team.totalAccounts}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body1">Locked: {team.totalLocked}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body1">Underperformed: {team.totalUnderperformed}</Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    })}
                </Grid>
            </Grid>

        </Grid>
    );
}
