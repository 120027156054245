export default class ReportViewModel {
    public id: number = 0;
    public name: string = '';
    public campaignId: number = 0;
    public packageName: string = '';
    public price: string = '';
    public country: string = '';
    public monthlyPrice: number = 0;
    public activityData: ActivityData = new ActivityData();
    public opportunityData: OpportunityData = new OpportunityData();
    public reportStartDate: string = '';
    public reportEndDate: string = '';
    public campaignName: string = '';
}

export class SortReportViewModel {
    public id: number = 0;
    public name: string = '';
    public campaignId: number = 0;
    public packageName: string = '';
    public price: string = '';
    public country: string = '';
    public monthlyPrice: number = 0;
    public connections: number = 0;
    public messages: number = 0;
    public opportunitiesEmail: number = 0;
    public opportunitiesPhone: number = 0;
    public acceptanceRate: number = 0;
    public reportStartDate: string = '';
    public reportEndDate: string = '';
    public followUps: number = 0;
    public pageInvites: number = 0;
    public withdrawals: number = 0;
    public campaignName: string = '';
    public freeInMails: number = 0;
    public paidInMails: number = 0;
}

export class SortOppReportViewModel {
    public id: number = 0;
    public name: string = '';
    public client: string = '';
    public account: string = '';
    public companyName: string = '';
    public link: string = '';
    public date: string = '';
    public reportStartDate: string = '';
    public reportEndDate: string = '';
}

export class UserReportViewModel {
    public user: string = '';
    public reports: ReportViewModel[] = [];
}

export class Data {
    public date: Date = new Date();
    public value: number = 0;
}

export class ActivityData {
    public name: string = '';
    public connections: Data[] = [];
    public messages: Data[] = [];
    public acceptanceRates: Data[] = [];
}

export class OpportunityData {
    public name: string = '';
    public opportunities: Data[] = [];
}

export class Filters {
    clientId: number = 0;
    execId: string = '';
    teamLeaderId: string = '';
    statusId: string[] = [];
    client: string = '';
    teamId: string = '';
}

