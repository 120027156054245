export default class {
    public campaignName = '';
    public package = '';
    public complete = false;

    public websiteViewed = '';
    public clientURL = '';

    public clientBase = '';
    public vaTNumber = '';
    public reasonForCall = false;
    public clientDeepDive = '';
    public leadGeneration = '';
    public orderValueAndCycle = '';
    public initialObjections = '';
    public desiredOutcome = '';
    public targetGeography = '';
    public targetIndustry = '';
    public targetSizeSelf = false;
    public targetSize1_10 = false;
    public targetSize11_50 = false;
    public targetSize51_200 = false;
    public targetSize201_500 = false;
    public targetSize501_1000 = false;
    public targetSize1001_5000 = false;
    public targetSize5001_10000 = false;
    public targetSize10000plus = false;
    public exampleClients = '';
    public avoidClients = '';
    public competitors = '';
    public targetJobTitle = '';
    public painPoint = '';
    public dreamClient = '';
    public leadOutcome = '';
    public calendarLink = '';
    public messageTone = '';
    public packageElementConversationManagement = false;
    public packageElementCRMIntegration = false;
    public packageElementProspectEngagement = false;
    public packageElementCompanyPageFollowers = false;
    public packageElementArticle = false;
    public monthlyArticleContact = '';
    public adsReferral = "";
    public emailCampaign = "";

    public velocityPurchase = false;
    public velocityWarmUp = false;
    public velocityForwarding = false;
    public velocityAltEmail = false;
    public emailDomain = '';
    public blacklist = '';
    public velocityQuestions = '';

    public startDate = '';
    public accountName = '';
    public logInUser = '';
    public logInPass = '';
    public telephone = '';
    public nextStepsLogInDetails = false;
    public nextStepsSearchSetUp = false;
    public nextStepsProfileReview = false;
    public nextStepsGoLive = false;
    public nextStepsReview = false;
    public recurringCall = '';
    public communicationChannel = '';
    public customerSurvey = '';
    public nextStepQuestions = '';
}